export const pageTitle = 'Kaseton reklamowy - nieregularny kształt';
export const pageDescription = 'Jeśli szukasz efektownych kasetonów o nieregularnym kształcie - zapraszamy do oferty. Produkujemy reklamy świetlne z nietypowymi literami przestrzennymi czy logiem.';
export const mainHeader = 'Kaseton reklamowy';

export const cofferInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**<span class="orange">Czas realizacji</span>**
      \n 5 - 10 dni roboczych. Do czasu realizacji nie jest wliczany czas ewentualnego umówienia terminu montażu.`,
    },
  ],
  rightColumn: [
    {
      article: `## ${mainHeader}
      \n Możliwość wykonania kasetonu w dowolnie wybranym kształcie. Może to być indywidualny kształt logo, litery przestrzenne lub inna pomysłowa forma reklamy świetlnej. Plafon wykonany jest z giętych taśm aluminiowych z 
      możliwością pomalowania na wybrany kolor z palety RAL lub oklejenie barwioną folią ORACAL i lica z plexi opal podświetlanej całościowo lub z Dibondu podświetlane tylko w wyfrezowanych miejscach.
      `,
    },
    {
      article: `**Specyfikacja**
      \n * Lico: Plexi opal lub Dibond
      \n * Boki: Taśma aluminiowa lakierowana proszkowo
      \n * Grubość kasetonu: 8cm
      \n * Plecy: Płyta PCV 5mm
      \n * Zasilanie i LED: Mean Well / Refond
      `,
    },
    {
      article: `**Opcje dodatkowe**
      \n * Czujnik zmierzchu:  \n Automatycznie włącza i wyłącza podświetlenie w zależności od pory dnia; 
      możliwość regulacji czułości czujnika - **<span class="orange">100 zł</span>**
      `,
    },
    {
      article: `**Inny wymiar i kształt?**
      \n Wycena indywidualna.
      `,
    },
    {
      article: `**Informacje potrzebne przy zamówieniu**
      \n * wybrany rozmiar
      \n * wybrany kształt
      \n * kolor obudowy <a href="https://banerbunny.pl/szablony/kasetony/kolory-obudowy-wzornik.pdf" target="_blank" download>pobierz plik</a>
      `,
    },
    {
      article: `**${mainHeader} kształt**
      \n * kaseton w kształcie logo
      \n * kaseton w kształcie krzyża
      \n * kaseton w kształcie koła
      \n * kaseton w kształcie elipsy
      \n * kaseton z zaokrąglonymi rogami
      `,
    },
  ],
  wide: [
    {
      article: `**<span class="orange">Koszt wysyłki</span>**
      \n * Kurier standard (przy kasetonach gdzie suma szerokości i wysokości nie przekracza 200cm) - 50 zł
      \n * Kurier gabaryt (przy kasetonach gdzie suma szerokości i wysokości przekracza 200cm) - 100 zł
      \n * Dowóz osobisty na terenie Warszawy i okolic - 100 zł
      \n * Dowóz osobisty w całej Polsce - wycena indywidualna
      \n * Podane ceny są cenami netto.
      `,
    },
    {
      slogan: '**<span class="center"><span class="orange">Tanie kasetony</span> - Twoja reklama święcąca nad sklepem</span>**',
    },
  ],
};
